nav {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  transition: all 0.3s ease-in-out;
}

.top-nav {
  padding: 1.25rem 1rem; /* Slightly larger initial padding */
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(240, 248, 255, 0.8));
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0.75rem 1rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(245, 245, 245, 0.9));
  backdrop-filter: blur(10px); /* Glassmorphism effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

nav.sticky.scrolled {
  background: linear-gradient(135deg, rgba(245, 245, 245, 0.95), rgba(255, 255, 255, 0.9));
}

/* Logo Styling */
.logo-glow {
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
}

/* Desktop Nav Links */
.nav-link {
  position: relative;
  color: #2d3748; /* Dark gray for contrast */
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 50%;
  background: linear-gradient(to right, #3182ce, #63b3ed); /* Gradient underline */
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-link:hover {
  color: #3182ce;
  transform: scale(1.05);
}

.nav-link:hover::after {
  width: 100%;
  left: 0;
}

/* Mobile Menu */
.mobile-menu {
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.3s ease;
}

.dropdown-content:focus-within .mobile-menu,
.dropdown-content:hover .mobile-menu {
  transform: translateY(0);
  opacity: 1;
}

.nav-link-mobile {
  color: #2d3748;
  text-decoration: none;
  transition: color 0.3s ease, background 0.3s ease;
}

.nav-link-mobile:hover {
  color: #3182ce;
  background: rgba(240, 248, 255, 0.5);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  nav {
    padding: 1rem 0.75rem;
  }

  .top-nav {
    padding: 1rem 0.75rem;
  }

  .sticky {
    padding: 0.5rem 0.75rem;
  }

  .mobile-menu {
    width: 90%;
    max-width: 220px;
    right: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .btn-circle {
    padding: 0.375rem;
    border-radius: 50%;
    transition: background 0.3s ease;
  }

  .btn-circle:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

/* Desktop adjustments */
@media (min-width: 769px) {
  .container {
    max-width: 1240px;
  }
}