.hero {
  background-image: url("../../assets/home.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 60px);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.hero-content {
  position: relative;
  z-index: 1;
  padding: 1rem 0.5rem;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.blur-border {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;
}

.blur-border::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  z-index: -1;
  background: inherit;
  filter: blur(8px);
  opacity: 0.6;
  transition: all 0.3s ease;
}

.blur-border:hover::before {
  background-color: rgba(62, 99, 138, 0.5);
  filter: blur(12px);
  opacity: 0.8;
}

/* Touch-friendly class for buttons */
.touch-friendly {
  min-width: 44px; /* Slightly smaller tap target, still accessible */
  min-height: 44px;
  padding: 0.5rem 0.75rem; /* Default smaller padding */
}

/* Media Queries for Mobile Optimization */
@media (max-width: 768px) {
  .hero {
    background-attachment: scroll;
  }

  .hero-content {
    padding: 0.75rem 0.5rem;
  }

  .blur-border::before {
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    filter: blur(6px);
  }

  .blur-border:hover::before {
    filter: blur(6px);
    background-color: transparent;
    opacity: 0.6;
  }

  .touch-friendly {
    padding: 0.375rem 0.625rem; /* Even smaller on tablets */
    font-size: 0.875rem; /* 14px */
  }
}

@media (max-width: 480px) {
  .hero {
    background-size: cover;
  }

  .hero-content {
    padding: 0.5rem 0.25rem;
  }

  .blur-border {
    transition: none;
  }

  .blur-border::before {
    filter: blur(4px);
    opacity: 0.5;
  }

  .blur-border:hover::before {
    filter: blur(4px);
    opacity: 0.5;
    background-color: transparent;
  }

  .touch-friendly {
    padding: 0.25rem 0.5rem; /* Smallest padding on phones */
    font-size: 0.75rem; /* 12px */
  }
}

/* Fallback for backdrop-filter support */
@supports (-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px)) {
  .blur-border::before {
    backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.1);
  }
}